import $$ from 'jquery'
import ExoForm from '@/common/FF/ExoForm'

import bsCustomFileInput from 'bs-custom-file-input'

const FF = {
    forms: [],
    init() {
        //todo pass theme from server
        bsCustomFileInput.init()
        $$('.ff-exo-form').each((index, el) => {
            this.forms.push(new ExoForm($$(el)))
        })
    },
    findById(form_id) {
        return this.forms.filter(form => +form_id === form.getFormId())
    },
    findByName(form_name) {
        return this.forms.filter(form => form_name === form.getFormName())

    },
    first(identifier) {
        return this.forms.find(exoForm => identifier === exoForm.getFormName() || identifier == exoForm.getFormId())
    },
    initReCaptcha(){
        this.forms.forEach(form=>form.initCaptcha())
    }
}
//expose FF to client site js
window.FF = FF;
window._initReCaptcha = FF.initReCaptcha.bind(FF)

export default FF;